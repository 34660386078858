<template>
  <HeaderComp />
  <router-view />
  <FooterComp />
</template>

<script>
import HeaderComp from "@/components/HeaderComp.vue";
import FooterComp from "@/components/FooterComp.vue";

export default {
  components: {
    HeaderComp,
    FooterComp,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Jacques+Francois+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quintessential&display=swap");

body {
  margin: 0% 15%;
  background-color: rgb(226, 239, 217);
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
}

h3 {
  font-family: "Quintessential", cursive;
  font-style: italic;
  text-align: center;
  color: rgb(75, 153, 141);
}

h4 {
  font-style: italic;
  text-decoration: underline;
}
</style>
