import {
  createRouter,
  createWebHistory
} from 'vue-router'


const routes = [{
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/infos',
    name: 'infos',
    component: () => import('../views/InfoView.vue')
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('../views/LegalView.vue')
  },
  {
    path: '/massage-energetique',
    name: 'massage-energetique',
    component: () => import('../views/EnergyView.vue')
  },
  {
    path: '/soin-lahochi',
    name: 'soin-lahochi',
    component: () => import('../views/LahochiView.vue')
  },
  {
    path: '/formation-lahochi',
    name: 'formation-lahochi',
    component: () => import('../views/FormationView.vue')
  },
  {
    path: '/soin-a-distance',
    name: 'soin-a-distance',
    component: () => import('../views/DistantView.vue')
  },
  {
    path: '/atelier-bien-etre',
    name: 'atelier-bien-etre',
    component: () => import('../views/WellBeingView.vue')
  },
  {
    path: '/:pathMatch(.*)',
    name: 'not-found',
    component: () => import('../views/NotFoundView.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/TermsView.vue')
  },
  {
    path: '/site-map',
    name: 'site-map',
    component: () => import('../views/SiteMapView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router