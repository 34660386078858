<template>
  <header>
    <h1>L’Angélite</h1>
    <h2>Massage et soin énergétique / Ateliers bien-être bébé/enfant</h2>
    <nav>
      <ul>
        <li class="nav"><router-link to="/">Accueil</router-link></li>
        <li class="dropdown">
          <a href="javascript:void(0)" class="dropbtn">Services</a>
          <div class="dropdown-content">
            <router-link to="soin-lahochi">Soin Lahochi</router-link>
            <router-link to="formation-lahochi">Formation Lahochi</router-link>
            <router-link to="soin-a-distance">Soin à distance</router-link>
            <router-link to="massage-energetique">Massage énergétique</router-link>
            <router-link to="atelier-bien-etre">Ateliers bien-être bébé/enfant</router-link>
          </div>
        </li>
        <li class="nav"><router-link to="infos">Informations</router-link></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "HeaderComp",
};
</script>

<style>
header {
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

nav>ul {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: space-around;
}

nav>ul a {
  font-family: "Quintessential", cursive;
  font-style: italic;
  color: rgb(75, 153, 141);
}

nav>ul a:hover {
  color: #aaf;
  text-decoration: underline;
}

li.nav a,
.dropbtn {
  display: inline-block;
  font-family: "Quintessential", cursive;
  font-style: italic;
  color: rgb(75, 153, 141);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown:hover .dropdown-content {
  display: block;
}

h1 {
  font-family: "Jacques Francois Shadow", cursive;
  text-align: center;
  color: rgb(56, 86, 35);
}

h2 {
  text-align: center;
  color: rgb(75, 153, 141);
}
</style>
