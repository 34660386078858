<template>
  <footer>
    <img src="../assets/img/bottom.jpg" alt="" />
    <nav>
      <ul id="footer">
        <li><router-link to="legal" @click="toTop">Mentions légales</router-link></li>
        <li><router-link to="terms" @click="toTop">Conditions générales</router-link></li>
        <li><router-link to="site-map" @click="toTop">Plan du site</router-link></li>
      </ul>
    </nav>
  </footer>
</template>

<script>
export default {
  name: "FooterComp",
  methods: {
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  }
};
</script>

<style>
footer {
  margin-top: 50px;
}

a {
  text-decoration: none;
}
</style>
